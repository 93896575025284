import React, { useState, useContext }  from "react"
import {GlobalStateContext} from '../context'
import axios from "axios";
import { Button, Form, Message } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledForm = styled(Form)`
    width: 40rem;
    @media (max-width: 860px) {
        width: 30rem;
      }
    @media (max-width: 555px) {
    width: 20rem;
    }
    @media (max-width: 390px) {
    width: 15rem;
    }
`

const Label = styled.label`
    color: inherit!important;
`
const ContactForm = (theme) => {
      const state = useContext(GlobalStateContext);

      const [serverState, setServerState] = useState({
        submitting: false,
        status: null
      });
      const [success, setSuccess] = useState(false)
      const [error, setError] = useState(false)

      const handleServerResponse = (ok, msg, form) => {
        setServerState({
          submitting: false,
          status: { ok, msg }
        });
        if (ok) {
          setSuccess(true);
          form.reset();
        } else {
          setError(true);
        }
      };
      const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
          method: "post",
          url: "https://getform.io/f/24cc4725-6650-48d5-9152-6bf935d54003",
          data: new FormData(form)
        })
          .then(r => {
            handleServerResponse(true, "Thanks!", form);
          })
          .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
          });
      };

      return (
      <StyledForm
        success={success}
        error={error}
        onSubmit={handleOnSubmit}
        >
        <Form.Field required={true}>
          <Label>{state.language === 'ptBR' ? "Nome" : "Name"}</Label>  
          <input type="text" name="name"/>
        </Form.Field>
        <Form.Field
          required={true}
        >
          <Label>E-mail</Label>
          <input type="email" name="email"/>
        </Form.Field>
        <Form.Field
            required={true}
        >
          <Label>{state.language === 'ptBR' ? "Sua mensagem" : "Your message"}</Label>
          <textarea type="text" name="message"/>
        </Form.Field>
        <Message
        success
        header={state.language === 'ptBR' ? "Mensagem enviada!" : 'Message sent!'}
        content={state.language === 'ptBR' ? "Responderei assim que possível." : 'I will respond to you as soon as I can.'}
        />
        <Message
        error
        header={state.language === 'ptBR' ? "Algo não está certo." : 'Something is wrong.'}
        content={state.language === 'ptBR' ? "Sua mensagem não foi enviada." : 'Your message was not sent.'}
        />
        <Button type='submit'>{state.language === 'ptBR' ? "Enviar" : "Submit"}</Button>
      </StyledForm>
    )
  }

export default ContactForm
