import React, { useContext } from "react"
import styled from "styled-components"
import { Container } from "react-awesome-styled-grid"
import siteConfig from "../../data/siteConfig"
import loadable from "@loadable/component"
import Hero from "../components/hero"
import Wrapper from "../components/wrapper"
import ContactForm from "../components/form"
import { GlobalStateContext } from "../components/context"
import SEO from "../components/SEO"

const Layout = loadable(() => import("../components/layout"))

const StyledWrapper = styled(Wrapper)`
  border: none;
  display: flex;
  flex-direction: column;
  top: -80px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.wrapperBackground};
  color: ${({ theme }) => theme.colors.wrapperTextColor};
  div > div > div * {
    color: ${({ theme }) => theme.colors.wrapperTextColor};
  }
  @media (max-height: 900px) {
    top: -120px;
  }

  @media (max-height: 667px) {
    top: -80px;
  }
`

const Contato = ({ className, location, theme }) => {
  const state = useContext(GlobalStateContext)
  const { keywords } = siteConfig
  const SEOImage = siteConfig.siteContactImage
  const SEOtitle = state.language === "ptBR" ? "Contato" : "Contact"

  return (
    <>
      <SEO
        image={SEOImage}
        title={SEOtitle}
        keywords={keywords}
        pathname={location.pathname}
        article
      />
      <Layout location={location.pathname}>
        <Hero
          heroImg={siteConfig.siteCover}
          title={state.language === "ptBR" ? "Contato" : "Contact"}
        />
        <StyledWrapper className={className}>
          <Container className="page-content" fluid>
            <ContactForm theme={theme} />
          </Container>
        </StyledWrapper>
      </Layout>
    </>
  )
}

export default styled(Contato)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
`
